import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../utils/getHtmlFromRedactorField';
import { RedactorHeader } from '../components/common/RedactorHeader';
import getSlug from '../utils/getSlug';
import Dron1 from '../images/dron1.png';
import Dron2 from '../images/dron2.png';
import '../utils/keyframes.css';
import Logo from '../images/Banner/Logo.svg';
import { LogoImage, TopMenuContainer } from '../components/menu/Menu';

const Container = styled.div`
	margin: 0 auto;
	max-width: 1440px;
	padding: ${({ theme }) => theme.padding.bigPadding};
	position: relative;
	z-index: 10;
	@media (max-width: 1440px) {
		max-width: 100%;
		padding: 0 10%;
	}
	@media (max-width: 768px) {
		max-width: 100%;
		padding: ${({ theme }) => theme.padding.smallPadding};
	}
`;

const ModuleWrapper = styled.div`
	background-image: url(${({ bgImg }) => bgImg});
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	display: flex;
	height: 100vh;
	position: relative;
	width: 100%;

	@media (max-width: 1440px) {
		width: 100%;
		padding: 0 10%;
		background-position: left;
		background-repeat: no-repeat;
		background-size: 72% auto;
	}
	@media (max-width: 1200px) {
		background-size: 85% auto;
	}
	@media (max-width: 900px) {
		background-size: 95% auto;
	}

	@media (max-width: 768px) {
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		width: 100%;
		height: 95vh;
	}
	&::after {
		animation: animation1 2s infinite alternate ease-in-out forwards;
		background-image: url(${Dron2});
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		bottom: 0;
		content: '';
		height: 100px;
		left: 0;
		margin: auto;
		position: absolute;
		right: -90%;
		top: 0;
		width: 200px;
		@media (max-width: 1440px) {
			right: -100%;
			top: -20%;
			bottom: 0;
			left: 0;
		}
		@media (max-width: 768px) {
			right: -80%;
			top: -60%;
			bottom: 0;
			left: 0;
		}
		@media (max-width: 500px) {
			width: 100px;
			height: 50px;
		}
	}
	&::before {
		animation: animation2 3s infinite alternate ease-in-out forwards;
		background-image: url(${Dron1});
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		bottom: 0;
		content: '';
		height: 100px;
		left: -60%;
		margin: auto;
		position: absolute;
		right: 0;
		top: -40%;
		width: 200px;
		@media (max-width: 1440px) {
			right: 0;
			top: -50%;
			bottom: 0;
			left: -70%;
		}
		@media (max-width: 768px) {
			right: 0;
			top: -20%;
			bottom: 0;
			left: -70%;
		}
		@media (max-width: 500px) {
			width: 100px;
			height: 50px;
		}
	}
`;

const TextWrapper = styled.div`
	align-self: flex-end;
	margin-bottom: 15%;
	margin-left: auto;
	margin-right: 0;
	text-align: end;
	@media (max-width: 500px) {
		padding-top: 40px;
		align-self: flex-end;
		text-align: left;
		justify-content: left;
		margin-left: 0;
	}
`;

const BackElementWrapper = styled.div`
	color: #ff2bf1;
	display: flex;
	font-size: 20px;
	font-weight: ${({ theme }) => theme.fontWeight.extraBold};
	justify-content: flex-end;
	margin-top: 20px;
	@media (max-width: 500px) {
		justify-content: flex-start;
	}
	a {
		color: #ff2bf1;
		margin-right: 15px;
		text-decoration: none;

		@media (max-width: 500px) {
			font-size: 16px;
			line-height: 10px;
			text-align: left;
		}
	}
`;

const ErrorPage = ({ data: allData }) => {
	const errorPageData = allData?.errorPageData?.nodes?.[0] || '';
	const header = errorPageData?.header || '';
	const image = errorPageData?.image?.url || '';
	const arrowIcon = errorPageData?.arrowIcon?.url || '';
	const innerLinkText = errorPageData?.innerLinkText || '';
	const innerLinkSlug = errorPageData?.innerLink?.slug || '';

	return (
		<>
			<TopMenuContainer>
				<Link to={`/${getSlug(innerLinkSlug)}`}>
					<LogoImage src={Logo} />
				</Link>
			</TopMenuContainer>
			<Container>
				<ModuleWrapper bgImg={image}>
					<TextWrapper>
						<RedactorHeader className="error-page navy-blue" dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
						<BackElementWrapper>
							<Link to={`/${getSlug(innerLinkSlug)}`}>{innerLinkText}</Link>
							<img src={arrowIcon} alt="" />
						</BackElementWrapper>
					</TextWrapper>
				</ModuleWrapper>
			</Container>
		</>
	);
};

export default ErrorPage;

export const query = graphql`
	query ErrorPageQuery {
		errorPageData: allDatoCmsErrorPage(filter: { locale: { eq: "pl" } }) {
			nodes {
				header
				image {
					url
				}
				innerLinkText
				innerLink {
					slug
				}
				arrowIcon {
					url
				}
			}
		}
	}
`;
